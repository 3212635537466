import SiteButton_ShinyButtonISkinComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/BaseButton/ShinyButtonISkin.skin';


const SiteButton_ShinyButtonISkin = {
  component: SiteButton_ShinyButtonISkinComponent
};


export const components = {
  ['SiteButton_ShinyButtonISkin']: SiteButton_ShinyButtonISkin
};

